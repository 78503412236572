import { auth, firebaseAuth, firebaseImport, db, app, firebaseGetMultiFactorResolver } from './config/main_config';
import { signInWithEmailAndPassword } from './variableModules/auth.module';
import { getMultiFactorResolver } from 'firebase/auth';

window.signInWithEmailAndPassword = signInWithEmailAndPassword;

var loginWithEmailBtn = document.getElementById('loginWithEmailBtn');
var googleSSOSignIn = document.getElementById('googleSSOSignIn');
var microsoftSSOSignIn = document.getElementById('microsoftSSOSignIn');
var emailLogin = document.getElementById('emailLogin');
var passwordLogin = document.getElementById('passwordLogin');
var loginDiv = document.getElementById('loginDiv');
var mfaDiv = document.getElementById('mfaDiv');
var verifyCode = document.getElementById('verifyCode');
var verifyCodeBtn = document.getElementById('verifyCodeBtn');
var resetPassword = document.getElementById('resetPassword');
var resetPasswordDiv = document.getElementById('resetPasswordDiv');
var emailResetPassword = document.getElementById('emailResetPassword');
var sendResetEmail = document.getElementById('sendResetEmail');
var resetPassErrLbl = document.getElementById('resetPassErrLbl');
var resolver;

emailLogin.addEventListener('keydown', function (e) {
  if (e.key === 'Enter') {
    passwordLogin.focus();
  }
});

passwordLogin.addEventListener('keydown', function (e) {
  if (e.key === 'Enter') {
    loginWithEmailBtn.click();
  }
});

loginWithEmailBtn.addEventListener('click', async function () {
  let email = emailLogin.value;
  let password = passwordLogin.value;

  if (email.length > 1 && password.length >= 8) {
    loginWithEmailBtn.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
    await auth
      .signInWithEmailAndPassword(email, password)
      .then(function (user) {
        if (auth.currentUser.emailVerified !== true) {
          auth.currentUser.sendEmailVerification();
        }
        window.location.href = 'index.html';
      })
      .catch(function (error) {
        console.log('Error:', error);
        console.log('Error Resolver:', error.resolver);
        if (error.code === 'auth/multi-factor-auth-required') {
          resolver = firebaseGetMultiFactorResolver(auth, error);
          console.log('Resolver:', resolver);
          // resolver = error.resolver;
          if (resolver) {
            handle2FA(resolver);
          } else {
            console.error('Resolver is undefined!');
          }
        } else {
          document.querySelector('.errorLabel').style.display = 'block';
          loginWithEmailBtn.innerText = `Login`;
        }
      });
  } else {
    document.querySelector('.errorLabel').style.display = 'block';
    loginWithEmailBtn.innerText = `Login`;
  }
});
googleSSOSignIn.addEventListener('click', function () {
  googleSSOSignIn.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
  const provider = new firebaseAuth.GoogleAuthProvider();
  auth
    .signInWithPopup(provider)
    .then(function (user) {
      window.location.href = 'index.html';
    })
    .catch(function (error) {
      console.log(error);
      if (error.code === 'auth/multi-factor-auth-required') {
        resolver = firebaseGetMultiFactorResolver(auth, error);
        handle2FA(resolver);
      } else {
        document.querySelector('.errorLabel').style.display = 'block';
      }
    });
});
microsoftSSOSignIn.addEventListener('click', function () {
  microsoftSSOSignIn.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
  const provider = new firebaseAuth.OAuthProvider('microsoft.com');
  auth
    .signInWithPopup(provider)
    .then(function (user) {
      window.location.href = 'index.html';
    })
    .catch(function (error) {
      console.log(error);
      if (error.code === 'auth/multi-factor-auth-required') {
        resolver = firebaseGetMultiFactorResolver(auth, error);
        handle2FA(resolver);
      } else if (error.code === "auth/account-exists-with-different-credential") {
        let pendingCredential = error.credential;
        let email = error.email;
        promptPasswordLink(email, pendingCredential);
      } else {
        document.querySelector('.errorLabel').style.display = 'block';
      }
    });
});

resetPassword.addEventListener('click', function () {
  loginDiv.style.display = 'none';
  resetPasswordDiv.style.display = 'flex';
});

sendResetEmail.addEventListener('click', function () {
  if (emailResetPassword.value !== (undefined || null || '')) {
    sendResetEmail.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
    auth
      .sendPasswordResetEmail(emailResetPassword.value)
      .then(function () {
        sendResetEmail.innerHTML = `Email sent&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M14.72 8.79L10.43 13.09L8.78 11.44C8.69036 11.3353 8.58004 11.2503 8.45597 11.1903C8.33191 11.1303 8.19678 11.0965 8.05906 11.0912C7.92134 11.0859 7.78401 11.1091 7.65568 11.1594C7.52736 11.2096 7.41081 11.2859 7.31335 11.3833C7.2159 11.4808 7.13964 11.5974 7.08937 11.7257C7.03909 11.854 7.01589 11.9913 7.02121 12.1291C7.02653 12.2668 7.06026 12.4019 7.12028 12.526C7.1803 12.65 7.26532 12.7604 7.37 12.85L9.72 15.21C9.81344 15.3027 9.92426 15.376 10.0461 15.4258C10.1679 15.4755 10.2984 15.5008 10.43 15.5C10.6923 15.4989 10.9437 15.3947 11.13 15.21L16.13 10.21C16.2237 10.117 16.2981 10.0064 16.3489 9.88458C16.3997 9.76272 16.4258 9.63201 16.4258 9.5C16.4258 9.36799 16.3997 9.23728 16.3489 9.11542C16.2981 8.99356 16.2237 8.88296 16.13 8.79C15.9426 8.60375 15.6892 8.49921 15.425 8.49921C15.1608 8.49921 14.9074 8.60375 14.72 8.79ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#FFFFFF"/>
      </svg>`;
        sendResetEmail.style.background = '#06AD5D';
        sendResetEmail.style.color = 'white';
        sendResetEmail.style.border = '2px solid #098047';
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        resetPassErrLbl.style.display = 'block';
      });
  } else {
    resetPassErrLbl.style.display = 'block';
  }
});

const handle2FA = async (resolver, pendingCred = null) => {
  loginDiv.style.display = 'none';
  mfaDiv.style.display = 'flex';
  
  if (!window.smsAppVerifier) {
    await generateRecaptcha('verifyPhoneScreenSignUpBtn');
  }
  let appVerifier = window.smsAppVerifier;
  
  // Check if multiple phone numbers are available
  if (resolver.hints.length > 1) {
    const phoneNumberSelection = document.getElementById('phoneNumberSelection');
    const phoneNumberOptions = document.getElementById('phoneNumberOptions');
    const verificationInputDiv = document.getElementById('verificationInputDiv');
    
    phoneNumberSelection.style.display = 'block';
    verificationInputDiv.style.display = 'none';
    phoneNumberOptions.innerHTML = '';
    
    // Create buttons for each phone number
    resolver.hints.forEach((hint, index) => {
      const phoneNumber = hint.phoneNumber;
      const lastFourDigits = phoneNumber.slice(-4);
      const button = document.createElement('button');
      button.className = 'button-primary';
      button.style.marginBottom = '10px';
      button.innerText = `Phone number ending in ${lastFourDigits}`;
      
      button.addEventListener('click', () => {
        // Hide selection and show verification input
        phoneNumberSelection.style.display = 'none';
        verificationInputDiv.style.display = 'block';
        document.getElementById('selectedPhoneText').innerText =
          `Verification code was sent to the number ending in ${lastFourDigits}`;
        
        // Proceed with verification for selected number
        handlePhoneVerification(resolver, hint, appVerifier, pendingCred);
      });
      
      phoneNumberOptions.appendChild(button);
    });
  } else {
    // Single phone number case
    const phoneNumber = resolver.hints[0].phoneNumber;
    const lastFourDigits = phoneNumber.slice(-4);
    document.getElementById('selectedPhoneText').innerText =
      `Verification code was sent to the number ending in ${lastFourDigits}`;
    handlePhoneVerification(resolver, resolver.hints[0], appVerifier, pendingCred);
  }
};

const handlePhoneVerification = (resolver, hint, appVerifier, pendingCred) => {
  var phoneInfoOptions = {
    multiFactorHint: hint,
    session: resolver.session,
  };
  var phoneAuthProvider = new firebaseAuth.PhoneAuthProvider();
  return phoneAuthProvider
    .verifyPhoneNumber(phoneInfoOptions, appVerifier)
    .then(function (verificationId) {
      verifyCode.addEventListener('keydown', function (e) {
        if (e.key === 'Enter') {
          verifyCodeBtn.click();
        }
      });
      verifyCodeBtn.addEventListener('click', function () {
        verifyCodeBtn.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
        if (verifyCode.value.length === 6) {
          var cred = firebaseAuth.PhoneAuthProvider.credential(verificationId, verifyCode.value);
          var multiFactorAssertion = firebaseAuth.PhoneMultiFactorGenerator.assertion(cred);
          resolver
            .resolveSignIn(multiFactorAssertion)
            .then(function (userCredential) {
              if (pendingCred !== null) {
                userCredential.user.linkWithCredential(pendingCred);
                if (auth.currentUser.emailVerified !== true) {
                  auth.currentUser.sendEmailVerification();
                }
                window.location.href = 'index.html';
              } else {
                window.location.href = 'index.html';
              }
            })
            .catch(function (error) {
              console.error(error);
              document.querySelector('.verifyErrorLabel').style.display = 'block';
              // Clear the verifyCode value and reset the onclick button
              verifyCode.value = '';
              verifyCodeBtn.innerHTML = 'Verify';
            });
        } else {
          document.querySelector('.verifyErrorLabel').style.display = 'block';
          // Clear the verifyCode value and reset the onclick button
          verifyCode.value = '';
          verifyCodeBtn.innerHTML = 'Verify';
        }
      });
    })
    .catch(function (error) {
      appVerifier.clear();
    });
};

auth.onAuthStateChanged(async function (user) {
  if (user) {
    window.__DebugUser = user;
    const token = await user.getIdToken();
    const uid = user.uid;
    document.cookie = `__session=${token}--__--${uid}`;
    window.location.reload(); // Let the server handle redirection
    /*
    await db
      .collection('users')
      .doc(user.uid)
      .get()
      .then((userDoc) => {
        if (userDoc.exists) {
          if (userDoc.data().roles?.owner === true) {
            window.location.href = 'index.html';
          } else {
            window.location = `https://app.${window.location.hostname.split('.')[1]}.${window.location.hostname.split('.')[2]}`;
          }
        }
      });
    */ // We need to implement this on the server side
  }
});

async function promptPasswordLink(email, pendingCred) {
  var loginDisplay = document.getElementById("loginDiv");
  var linkDiv = document.getElementById("linkMicrosoft");
  var linkBtn = document.getElementById("linkBtn");
  var linkErrorLbl = document.querySelector(".linkErrorLbl");
  var passwordLinking = document.getElementById("passwordLinking");
  loginDisplay.style.display = "none";
  linkDiv.style.display = "flex";
  passwordLinking.addEventListener('keydown', function (e) {
    if (e.key === 'Enter') {
      linkBtn.click();
    }
  });
  linkBtn.addEventListener("click", function () {
    linkBtn.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
    var password = passwordLinking.value;
    auth.signInWithEmailAndPassword(email, password).then(function (result) {
      return result.user.linkWithCredential(pendingCred);
    }).then(function () {
      linkBtn.innerHTML = `Microsoft Account Linked&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M14.72 8.79L10.43 13.09L8.78 11.44C8.69036 11.3353 8.58004 11.2503 8.45597 11.1903C8.33191 11.1303 8.19678 11.0965 8.05906 11.0912C7.92134 11.0859 7.78401 11.1091 7.65568 11.1594C7.52736 11.2096 7.41081 11.2859 7.31335 11.3833C7.2159 11.4808 7.13964 11.5974 7.08937 11.7257C7.03909 11.854 7.01589 11.9913 7.02121 12.1291C7.02653 12.2668 7.06026 12.4019 7.12028 12.526C7.1803 12.65 7.26532 12.7604 7.37 12.85L9.72 15.21C9.81344 15.3027 9.92426 15.376 10.0461 15.4258C10.1679 15.4755 10.2984 15.5008 10.43 15.5C10.6923 15.4989 10.9437 15.3947 11.13 15.21L16.13 10.21C16.2237 10.117 16.2981 10.0064 16.3489 9.88458C16.3997 9.76272 16.4258 9.63201 16.4258 9.5C16.4258 9.36799 16.3997 9.23728 16.3489 9.11542C16.2981 8.99356 16.2237 8.88296 16.13 8.79C15.9426 8.60375 15.6892 8.49921 15.425 8.49921C15.1608 8.49921 14.9074 8.60375 14.72 8.79ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#FFFFFF"/>
      </svg>`;
      setTimeout(() => {
        window.location.href = "index.html";
      }, 2500)
    }).catch((error) => {
      console.error(error)
      linkBtn.innerText = "Link Microsoft Account"
      if (error.code === 'auth/multi-factor-auth-required') {
        resolver = firebaseGetMultiFactorResolver(auth, error);
        linkDiv.style.display = "none";
        handle2FA(resolver, pendingCred);
      } else {
        linkErrorLbl.style.display = "flex";
      }
    })
  });
}

export const generateRecaptcha = async (el) => {
  const recaptchaVerifier = new firebaseAuth.RecaptchaVerifier('loginWithEmailBtn', {
    size: 'invisible',
    callback: function (response) {
      console.log('ReCaptcha solved!', response);
    },
    'error-callback': function (error) {
      console.error('ReCaptcha error:', error);
    }
  }, app);

  window.smsAppVerifier = await recaptchaVerifier;

  console.log(window.smsAppVerifier)
};